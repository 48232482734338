import { Elm } from "../Main.elm";

const domainConfigs = {
  "depohub.ai": {
    accent: "#1b694c",
    primary: "#36D399",
    secondary: "#333333",
    logo: new URL("../../assets/img/depohub-logo-light.png", import.meta.url)
      .href,
  },
  "csireporting.app": {
    accent: "#00012c",
    primary: "#3b4479",
    secondary: "#8296ce",
    logo: new URL(
      "../../assets/img/csi-court-reporting-logo.png",
      import.meta.url,
    ).href,
  },
  "usdepo.app": {
    accent: "#00012c",
    primary: "#3b4479",
    secondary: "#8296ce",
    logo: new URL("../../assets/img/usdepo-logo-blue.png", import.meta.url)
      .href,
  },
  default: {
    accent: "#1b694c",
    primary: "#36D399",
    secondary: "#333333",
    logo: new URL("../../assets/img/depohub-logo-light.svg", import.meta.url)
      .href,
  },
};

const domain = process.env.DEPO_AUTH_DOMAIN || window.location.hostname;
const config = domainConfigs[domain] || domainConfigs["default"];

// Set CSS variables for Tailwind dynamic colors
document.documentElement.style.setProperty("--accent", config.accent);
document.documentElement.style.setProperty("--primary", config.primary);
document.documentElement.style.setProperty("--secondary", config.secondary);

var app = Elm.Main.init({
  flags: {
    login_target_url: process.env.DEPO_LOGIN_TARGET_URL,
    depo_brief_url: process.env.DEPO_BRIEF_URL,
    depo_hub_url: process.env.DEPO_HUB_URL,
    depo_scriptly_url: process.env.DEPO_SCRIPTLY_URL,
    logo_src: config.logo,
    domain,
  },
  node: document.getElementById("elm-app"),
});

// --- RECAPTCHA Integration ---
function ensureRecaptchaContainer() {
  let container = document.getElementById("recaptcha");
  if (!container) {
    container = document.createElement("div");
    container.id = "recaptcha";
    container.style.display = "inline-block";
    document.body.appendChild(container);
  }
  return container;
}

app.ports.reloadRecaptcha.subscribe(() => {
  // Remove any existing reCAPTCHA script
  const existingScript = document.querySelector('script[src*="recaptcha"]');
  if (existingScript) {
    existingScript.remove();
  }

  // Ensure reCAPTCHA container is ready
  const container = document.getElementById("recaptcha");
  if (!container) {
    return;
  }
  container.innerHTML = "";

  // Add new reCAPTCHA script
  const recaptchaScript = document.createElement("script");
  recaptchaScript.src =
    "https://www.google.com/recaptcha/api.js?onload=onRecaptchaLoadCallback&render=explicit";
  recaptchaScript.async = true;
  recaptchaScript.defer = true;
  recaptchaScript.onerror = () =>
    console.error("Failed to load reCAPTCHA script");

  document.head.appendChild(recaptchaScript);
});

// Ensure reCAPTCHA is properly rendered
window.onRecaptchaLoadCallback = function () {
  grecaptcha.render("recaptcha", {
    sitekey: process.env.RECAPTCHA_SITE_KEY,
    callback: (token) => {
      app.ports.onCaptchaCompleted.send(token);
    },
    "error-callback": () => console.error("reCAPTCHA error occurred"),
  });
};

// Load reCAPTCHA initially
ensureRecaptchaContainer();

const recaptchaScript = document.createElement("script");
recaptchaScript.src =
  "https://www.google.com/recaptcha/api.js?onload=onRecaptchaLoadCallback&render=explicit";
recaptchaScript.async = true;
recaptchaScript.defer = true;
recaptchaScript.onerror = () =>
  console.error("Failed to load reCAPTCHA script");
document.head.appendChild(recaptchaScript);
