module Main exposing (..)

import App
import Browser
import Browser.Navigation as Nav
import Effect exposing (Effect)
import Entity.Flags as Flags exposing (Flags)
import Env exposing (Env)
import Html
import Json.Decode as Decode exposing (Decoder)
import Time
import Url exposing (Url)
import Url.Builder



-- MAIN


main : Program Decode.Value Model Msg
main =
    Effect.application
        { init = init
        , update = update
        , view = view
        , subscriptions = subscriptions
        , ignore = Ignored
        , onUrlRequest = UrlChanged
        , onUrlChange = LinkClicked
        }



-- MODEL


type alias Model =
    { app : App.Model
    , env : Env
    }


modelSetUrl : Model -> Url -> ( Model, Effect Msg )
modelSetUrl model url =
    let
        ( app, effect ) =
            App.setUrl model.app url
    in
    ( { model | app = app }, Effect.map AppMsg effect )


init : Decode.Value -> Url -> Nav.Key -> ( Model, Effect Msg )
init flagsJson url key =
    case Decode.decodeValue Flags.fromJson flagsJson of
        Ok flags ->
            initOk flags url key

        Err error ->
            initError error url key


initError : Decode.Error -> Url -> Nav.Key -> ( Model, Effect Msg )
initError error url key =
    let
        env =
            Env.init flags key Time.utc

        ( app, _ ) =
            App.showError env (Decode.errorToString error)

        flags =
            Flags.default
    in
    ( Model app env, Effect.none )


initOk : Flags -> Url -> Nav.Key -> ( Model, Effect Msg )
initOk flags url key =
    let
        ( app, effect ) =
            App.init env url

        env =
            Env.init flags key Time.utc
    in
    ( Model app env, Effect.batch [ Effect.map AppMsg effect, Effect.getTimeZone GotTimeZone ] )



-- UPDATE


type Msg
    = AppMsg App.Msg
    | Ignored String
    | LinkClicked Url
    | GotTimeZone Time.Zone
    | UrlChanged Browser.UrlRequest


update : Msg -> Model -> ( Model, Effect Msg )
update msg model =
    case msg of
        Ignored _ ->
            ( model, Effect.none )

        UrlChanged urlRequest ->
            case urlRequest of
                Browser.Internal url ->
                    let
                        ( newModel, effect ) =
                            modelSetUrl model url
                    in
                    ( newModel
                    , Effect.batch
                        [ effect
                        , Effect.pushUrl url
                        ]
                    )

                Browser.External href ->
                    ( model, Effect.loadUrl href )

        LinkClicked url ->
            modelSetUrl model url

        GotTimeZone zone ->
            let
                env =
                    Env.setTimeZone zone model.env
            in
            ( { model | env = env }, Effect.none )

        AppMsg appMsg ->
            let
                ( appModel, effect ) =
                    App.update appMsg model.app
            in
            ( { model | app = appModel }, Effect.map AppMsg effect )



-- SUBSCRIPTIONS


subscriptions : Model -> Sub Msg
subscriptions model =
    Sub.map AppMsg (App.subscriptions model.app)



-- VIEW


view : Model -> Browser.Document Msg
view model =
    { title = App.title model.app
    , body =
        [ Html.map AppMsg (App.view model.app)
        ]
    }
